<template>
  <div class="message-settings-wrap">
    <div class="title">消息设置</div>
    <div class="settings-wrap">
      <div class="settings-wrap-item" v-for="(item, index) in state.settings" :key="index">
        <div class="settings-wrap-item-icon">
          <img :src="item.icon" />
        </div>
        <div class="settings-wrap-item-content">
          <div class="settings-wrap-item-content_top">
            {{ item.title }}
          </div>
          <div class="settings-wrap-item-content_bottom">
            {{ item.desc }}
          </div>
        </div>
        <div class="settings-wrap-item-switch">
          <el-switch
            v-model="state.messageConfig[item.type]"
            :width="42"
            @change="onStatusChange($event, item.type)"
            active-color="#3fb838"
            inactive-color="#bbbbbb"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { getMessageConfig, updateMessageConfig } from '@/apis'
import { useUser } from '@/hooks'
const BASE_URL = process.env.BASE_URL
export default {
  name: 'messageSettings',
  setup () {
    const { user } = useUser()
    const state = reactive({
      messageConfig: {
        preApprove: false, // 待批申请
        preJob: false, // 	待办任务
        preWorkOrder: false, // 待办工单
        sysNotice: false // 系统通知
      },
      settings: [
        {
          icon: `${BASE_URL}images/message/message_work_order.png`,
          title: '待办工单',
          desc: '待办工单将以站内信的形式通知',
          type: 'preWorkOrder'
        },
        {
          icon: `${BASE_URL}images/message/message_apply.png`,
          title: '待批申请',
          desc: '待批申请将以站内信的形式通知',
          type: 'preApprove'
        },
        {
          icon: `${BASE_URL}images/message/message_sys.png`,
          title: '系统通知',
          desc: '系统通知将以站内信的形式通知',
          type: 'sysNotice'
        }
      ]
    })
    const initConfigInfo = async () => {
      try {
        const result = await getMessageConfig()
        if (result.data) {
          for (const key in state.messageConfig) {
            state.messageConfig[key] = result.data[key] || false
          }
        }
      } catch (error) {
        console.log('initConfigInfo error', error)
      }
    }
    initConfigInfo()
    const updateConfig = async (bool, type) => {
      try {
        const params = { id: user.value.uuid, [type]: bool }
        await updateMessageConfig(params)
      } catch (error) {
        console.log('updateConfig', error)
      }
    }
    const onStatusChange = (bool, type) => {
      updateConfig(bool, type)
    }
    return {
      state,
      onStatusChange
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
